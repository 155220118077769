import React , { useRef, useState } from "react";

import Template from "../components/Template/Template";
import IndexStyle from './indexStyle';

const IndexPage = () => {

    return (
        <Template path="/404">
            <IndexStyle id="wrapper">

                <h1></h1>
                Nie znaleziono strony

            </IndexStyle>
            
        </Template>
    )
}

export default IndexPage
